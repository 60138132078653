import { createBrowserRouter } from 'react-router-dom';
import Landing from './landing/Landing';
import App from './App';
import Dashboard from './dashboard/Dashboard';
import Manage from './outlet/Manage';
import Analytics from './outlet/Analytics';
import AuthProvider from './auth/auth';
import ProfilePage from '../components/Profile';
import ReportData from './outlet/ReportData';

const router = createBrowserRouter([
    {
        path: "/login",
        element: <AuthProvider children={<Landing />} />,
    },
    {
        path: "/",
        element: <App />
    },
    {
        path: "/dashboard",
        element: <AuthProvider children={<Dashboard />} />,
        children: [
            {
                path: "manage",
                index: true,
                element: <AuthProvider children={<Manage />} />,
            },
            {
                path: "analytics",
                index: true,
                element: <AuthProvider children={<Analytics />} />,
            },
            {
                path: "report-data",
                index: true,
                element: <AuthProvider children={<ReportData />} />,
            },
            {
                path: "profile",
                index: true,
                element: <AuthProvider children={<ProfilePage />} />,
            }
        ]
    },
    {
        path: "*",
        element: <div className='text-4xl text-red-300 h-screen flex items-center justify-center'>Not Found ☹️</div>
    }
]);

export default router;