
const Utils = {
    bufferToBase64(binary) {
        return binary.reduce((data, byte) => {
            return data + String.fromCharCode(byte);
        }, '');
    },
    SHEET_MAPPER: {
        0: ['name', 'specialization_name', 'course_name', 'important_topics'],
        1: ['course_name', 'specialization_name', 'specialization_brief', 'latest_technologies', 'description', 'impact'],
        2: ['course_name', 'specialization_name', 'project_name', 'project_details', 'software_required', 'functional_skillset_required', 'detailed_plan', 'learning_outcomes', 'difficulty_level'],
        3: ['course_name', 'specialization_name', 'job_type', 'job_role', 'criteria', 'skillset', 'industry_trend'],
        4: ['course_name', 'specialization_name', 'job_role', 'internship', 'skillset_required', 'activities', 'salary', 'learning_outcome'],
        5: ['course_name', 'specialization_name', 'industry_programs', 'organization', 'duration', 'eligibility', 'description'],
        6: ['course_name', 'specialization_name', 'job_role', 'year', 'sector', 'job_profile', 'salary_range'],
        7: ['course_name', 'specialization_name', 'job_role', 'skill', 'skill_details'],
        8: ['skill', 'level', 'course_name', 'platform', 'duration', 'description', 'cost', 'link'],
    },
    SHEET_URLS: [
        '/subject-details',
        '/latest-technologies',
        '/self-projects',
        '/job-roles',
        '/internships',
        '/industry-programs',
        '/career-growth',
        '/skills',
        '/certification'
    ]
}


export default Utils;