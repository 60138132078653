import { Box, CircularProgress } from "@mui/material";
import ReportSchema from "../../components/ReportSchemas";
import UploadExcelFile from '../../components/UploadExcelFile';
import { useState } from "react";

export default function ReportData() {
    const [selectedSchema, setSelectedSchema] = useState({ label: "", sheetNumber: -1 })
    const [loading, setLoading] = useState(false)

    return (
        <Box className="flex lg:flex-row items-center lg:gap-10 flex-col">
            <ReportSchema selectedSchema={selectedSchema} setSelectedSchema={setSelectedSchema} />
            <UploadExcelFile selectedSchema={selectedSchema} setLoading={setLoading} loading={loading} />
        </Box>
    )
}