import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as XLSX from 'xlsx';
import Utils from '../utils/utils'

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

async function readExcelFile(file, sheetNumber) {
    // Create a FileReader instance
    const reader = new FileReader();

    // Return a promise to handle async processing
    return new Promise((resolve, reject) => {
        reader.onload = (event) => {
            const arrayBuffer = event.target.result;
            const workbook = XLSX.read(arrayBuffer, { type: 'array' });

            // Example: Read the first worksheet
            const sheetName = workbook.SheetNames[sheetNumber];
            const worksheet = workbook.Sheets[sheetName];

            // Convert the worksheet to JSON
            const jsonData = XLSX.utils
                .sheet_to_json(worksheet, { header: 1 }).slice(1,)
                ?.filter(ele => ele.length !== 0)?.map((ele) => {
                    return ele.reduce((a, b, c) => {
                        a[Utils.SHEET_MAPPER[sheetNumber][c]] = b?.trim()
                        return a
                    }, {})
                });
            console.log(jsonData);
            resolve(jsonData);
        };



        reader.onerror = (error) => reject(error);

        // Read the file as an ArrayBuffer
        reader.readAsArrayBuffer(file);
    });
}


export default function UploadExcelFile({ selectedSchema, loading, setLoading }) {


    return (
        <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            disabled={selectedSchema.sheetNumber === -1 || selectedSchema.label === "" || loading}
        >

            {loading ? 'Loading' : 'Upload file'}
            <VisuallyHiddenInput
                type="file"
                onChange={async (event) => {
                    setLoading(true)
                    const file = event.target.files[0];
                    if (!file) {
                        console.error('No file selected');
                        setLoading(false)
                        return;
                    }

                    try {
                        const data = await readExcelFile(file, selectedSchema.sheetNumber)
                        const response = await fetch(`${process.env.REACT_APP_REPORT_BASE_URL}/api/report${Utils.SHEET_URLS[selectedSchema.sheetNumber]}`, {
                            method: 'POST', // Specify the request method
                            headers: {
                                'Content-Type': 'application/json' // Indicate the content type as JSON
                            },
                            body: JSON.stringify(data) // Convert the data object to a JSON string
                        })
                        console.log(response);
                        setLoading(false)
                    } catch (error) {
                        console.error('Error reading file:', error);
                    }
                }}
            />
        </Button>
    );
}
