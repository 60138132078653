import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { Analytics, Person, Settings, Article } from '@mui/icons-material';
import heyDuLogo from '../../images/logo.png';
import Profile from '../../api/profile';
import Login from '../../api/login';
import { useDispatch } from 'react-redux';
import { show } from '../../reducers/notificationSlice';
import { Avatar, Divider, Menu, MenuItem } from '@mui/material';
import Utils from '../../utils/utils';

const drawerWidth = 280;

const NAV_ITEMS = [
    {
        icon: <Settings />,
        text: "Manage",
        link: "/dashboard/manage"
    },
    {
        icon: <Article />,
        text: "Manage Report",
        link: "/dashboard/report-data"
    },
    {
        icon: <Analytics />,
        text: "Analytics",
        link: "/dashboard/analytics"
    }
]


function ProfileDropDown() {
    const dispatch = useDispatch();
    const [profile, setProfile] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('access-token')) {
            Profile.getAdminProfileDetails(localStorage.getItem('email'))
                .then(({ data }) => {
                    setProfile(data);
                })
                .catch((err) => dispatch(show({ message: err?.response?.data?.message, open: true, severity: "error" })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        navigate("/dashboard/profile");
        setAnchorEl(null);
    };

    return (
        <div className='flex w-full justify-end gap-2 md:text-lg items-center text-sm truncate'>
            <Typography variant="body" className='w-[100px] truncate text-right cursor-pointer' title={profile.name}>{profile.name}</Typography>
            <Box onClick={handleClick} className='cursor-pointer rounded-full'>
                {profile?.profile_img_binary ?
                    <Avatar src={Utils.bufferToBase64(profile?.profile_img_binary?.data)} alt='profile' />
                    :
                    <Person fontSize='large' className='border rounded-full border-red-200 p-1 cursor-pointer shadow-2xl' id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    />
                }
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    '& .MuiPaper-elevation': {
                        background: 'white', borderRadius: '10px', width: '120px', marginTop: '5px', marginLeft: '-10px',
                    },
                    '& ul li:hover': {
                        backgroundColor: 'black',
                        color: 'white',
                    }
                }}
            >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={() => {

                    Login.logout()
                        .then(({ data }) => {
                            localStorage.removeItem('access-token');
                            localStorage.removeItem("email");
                            navigate("/login");
                            dispatch(show({ message: data?.message, open: true, severity: "success" }))
                        }).catch((err) => dispatch(show({ message: err?.data?.message, open: true, severity: "error" })));
                }}>Logout</MenuItem>
            </Menu>
        </div>
    );
}

export default function Dashboard(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box className='flex flex-col h-full'>
            <Toolbar className='flex items-center gap-2 justify-center'>
                <img src={heyDuLogo} className='w-28' alt="logo" />
            </Toolbar>
            <Divider sx={{ borderBottomWidth: 1 }} />
            <List>
                {NAV_ITEMS.map((item, idx) => (
                    <NavLink to={item.link}
                        className={({ isActive }) => (isActive ? "active" : "")}
                        key={idx}>
                        <Typography variant='body' className='flex gap-2 items-center p-4'>
                            {item.icon}{item.text}
                        </Typography>
                    </NavLink>
                ))}
            </List>
        </Box >
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                color='transparent'
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <ProfileDropDown />
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 2, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <Outlet />
            </Box>
        </Box >
    );
}
